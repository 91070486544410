<template>
  <div class="pay">
    <!-- <div class="advert" @click="goActivity" >
       <img class="showImg" :src="advertitem" alt />
    </div> -->
    <div class="pay-header">
      <span>
        充值账号：
        <em>{{account}}</em>
      </span>
      <span>
        余额：
        <em>{{balance}}</em>
      </span>
      <a class="fr" @click="linkCardStockPay">
        前往卡劵充值
        <i class="iconfont iconyoujiantou"></i>
      </a>
    </div>
    <div class="pay-container">
      <div class="item">
        <p class="title">1.选择充值金额</p>
        <ul class="list">
          <li
            v-for="(item,index) in payList"
            :key="index"
            :class="[{ active: index == currentIndex }, { big: item.preferential == 0 }]"
            @click="selectPrice(index)" >
            <img v-if="index == 3 || index == 4 || index == 5 || index == 2" class="rec" src="@/assets/images/pay/rec.png" alt />
            <img class="cur" src="@/assets/images/pay/active.png" alt />
            <p class="text1">
              ￥<em class="font-orange">{{item.price}}</em>元
            </p>
            <p class="text2" v-if="item.preferential > 0">
              赠送<em class="font-red">{{item.preferential}}</em>元
            </p>
            <p class="text3" v-if="item.percent > 0">
              首充加赠<em class="font-red">{{item.percent}}%</em>
            </p>
          </li>
          <li class="bg-gray">
            <p class="text3">需要更大套餐</p>
            <p class="label">联系客服</p>
          </li>
        </ul>
      </div>
      <div class="item">
        <p class="title">2.选择支付方式</p>
        <ul class="type-list">
          <li
            v-for="(item,index) in payType"
            :key="index"
            @click="selectPayType(index)"
            :class="index == payTypeIndex ? 'active' : ''" >
            <i :class="'iconfont ' + item.icon"></i>
            <span>{{item.name}}</span>
            <img class="cur" :src="item.img" alt />
          </li>
        </ul>
      </div>
      <div class="item pay-btn-box">
        <el-button type="primary" @click="openPayPop">点击充值</el-button>
        <a class="agree" @click="agreen">
          <el-radio v-model="agreeRadio" label="1">阅读并同意</el-radio>
          <em>《使用声明》</em>
        </a>
      </div>

      <el-row class="item">
        <el-table :data="tableData">
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column label="订单号" prop="batch"></el-table-column>
          <el-table-column label="充值方式" :formatter="payFormat" ></el-table-column>
          <el-table-column label="充值金额" prop="payAmount"></el-table-column>
          <el-table-column label="充值时间" prop="payTime"></el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
                v-if="totalSize > 10"
        ></el-pagination>
      </el-row>
    </div>
    <Dialog
      :dialogVisible="dialogTableVisible"
      :dialogPaying= "isPaying"
      :curPreferential="currentPreferential"
      :currentPrice="currentPrice"
      :currentCouponId="currentCouponId"
      :currentPayType="currentPayType"
      @closeDialog="closePayPop"
      @onPay="changePay"
    />
  </div>
</template>

<script>
import Dialog from "./module/dialog.vue";
import { getSessionUser } from '../../api/auth';
import { getChargeList } from "../../api/order";
import { userCouponList } from  "../../api/coupon";
import { message } from '@/utils/resetMessage';
import { getToken, getTempUser,getNewUser, setNewUser} from "../../utils/storage";
import { MessageBox } from "element-ui";
export default {
  components: {
    Dialog
  },
  data() {
    return {
      isLoggedIn:false,
      account: "",
      balance: "",
      payList: [],
      codeList: [],
      tableData: [], //列表
      currentPage: 1,
      pageSize: 10,
      totalSize:0,
      currentIndex: 2, //价格下标
      payTypeIndex: 0, //支付类型下标
      currentPrice: 100, //价格
      currentPreferential: 10, //优惠
      currentPayType: "alipay", //支付类型
      dialogTableVisible: false, //支付弹窗
      //advertitem:require("@/assets/images/taogetu_advert.png"),
      payType: [
        /*{
          icon: "iconweixin",
          name: "微信",
          code: "wechat",
          img: require("@/assets/images/pay/arrow2.png")
        },*/
        {
          icon: "iconzhifubao",
          name: "支付宝",
          code: "alipay",
          img: require("@/assets/images/pay/active3.png")
        }
      ],
      agreeRadio: "1",
      isPaying:false, //是否还在支付中
      userCouponList:[],//用户优惠券列表
      currentCouponIndex: '', //优惠券下标
      currentCouponPrice:0,//优惠券价格
      currentCouponId:0,//优惠券id
      isNewUser:false,//是否新用户
    };
  },
  mounted() {
    this.isLoggedIn = ((getToken() != null && getToken()!='' ) && getTempUser() != 'temp') ? true : false;
    this.isNewUser = getNewUser();
    this.getUserList();
    this.getPayList();
    this.getTableData();
    //this.$store.commit("updateShowAdvert", true);
  },
  methods: {
    payFormat(row){
      if (row.payWay === 1) {
        return '卡券充值';
      }else if(row.payWay === 3){
        return '微信充值';
      }else{
        return '支付宝充值';
      }
    },
    getUserList() {
      getSessionUser({}).then((result) => {
        this.$store.dispatch("setUserInfo", result);
        if (this.$store.state.user.loginNameType) {
          this.account = this.$store.state.user.loginNameType == 1
                          ? this.$store.state.common.userInfo.mobile
                          : this.$store.state.common.userInfo.mail;
          this.balance = result.blance + "元";
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    goActivity() {
      this.$router.push({ path: "/activity" });
    },
    getPayList() {
      this.payList = [
        {
          price: 10,
          preferential: 0
        },
        {
          price: 20,
          preferential: 1
        },
        {
          price: 50,
          preferential: 3
        },
        {
          price: 100,
          preferential: 10
        },
        {
          price: 300,
          preferential: 40
        },
        {
          price: 500,
          preferential: 70
        },
        {
          price: 1000,
          preferential: 140
        },
        {
          price: 2000,
          preferential: 300
        },
      ];
    },
    getPayList1() {
      this.payList = [
        {
          price: 10,
          preferential: 0
        },
        {
          price: 20,
          preferential: 1
        },
        {
          price: 50,
          preferential: 3
        },
        {
          price: 100,
          preferential: 10
        },
        {
          price: 300,
          preferential: 40
        },
        {
          price: 500,
          preferential: 70
        },
        {
          price: 1000,
          preferential: 140
        },
        {
          price: 2000,
          preferential: 300
        },
      ];
    },
    selectPrice(index) {
        //设置默认到价格
        this.currentIndex = index;
        this.currentPrice = this.payList[index].price;
        this.currentPreferential = this.payList[index].preferential;
        //设置选中第一个可用的优惠券
        if(this.userCouponList!=null&&this.userCouponList.length!=0){//有可用优惠券
          for(var i=0;i<this.userCouponList.length;i++){
            if(this.currentPrice>=this.userCouponList[i].minAmount){//选中价格 大于优惠券最小使用值
              this.currentCouponIndex = i;
              this.currentCouponPrice=this.userCouponList[i].typeMoney;
              this.currentCouponId=this.userCouponList[i].user_coupon_id;
              break;
            }
          }
        }
    },
    selectCoupon(index) {//选择优惠券
      //设置默认到价格
      this.currentCouponIndex = index;
      this.currentCouponPrice = this.userCouponList[index].typeMoney;
      this.currentCouponId=this.userCouponList[index].user_coupon_id;
      console.log(this.currentCouponId);
    },
    //选择支付方式
    selectPayType(index) {
      this.payTypeIndex = index;
      this.currentPayType = this.payType[index].code;
    },
    //前往卡卷充值
    linkCardStockPay() {
      this.$router.push({ path: "/cardStockPay" });
      this.$store.commit("asideMenuId", 3);
    },
    //同意使用声明
    agreen() {
      this.$router.push({ path: "/statement" });
      this.$store.commit("asideMenuId", 7);
    },
    //点击充值跳转到支付窗口
    openPayPop() {
      if(this.isLoggedIn){
        //增加赠送值
        this.currentPreferential=this.currentPreferential+this.currentCouponPrice;
        this.dialogTableVisible = true;
      }else{
        message.error({
          message: '您还没登录用户，请登录',
          showClose: true,
          duration: 4000,
          offset: 100
        });
      }
    },
    closePayPop() {
      this.dialogTableVisible = false;
      this.getUserList();
      this.getTableData();
    },
    changePay(flag){ //更换支付状态
      this.isPaying = flag;
      this.getTableData();
    },
    getTableData() {
      let dataform = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        type:3
      };
      //获取充值历史列表
      getChargeList(dataform).then(result => {
        // 返回到上一个页面
        this.tableData=result.list;
        this.totalSize=result.count;
        // if((this.totalSize>0)){
        //   this.isNewUser = false;
        //   setNewUser(false);
        //   this.getPayList();
        //   this.selectPrice(2);
        // }else{
        //   this.isNewUser = true;
        //   setNewUser(true);
        //   this.getPayList1();
        //   this.selectPrice(1);
        // }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    getUserCouponDatas() {
      userCouponList().then((result) => {
        if(result!=null){
          this.userCouponList = result;
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
  }
};
</script>
<style lang="scss">
.pay-btn-box {
  .el-button {
    width: 110px;
    line-height: 44px;
    height: 44px;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .agree {
    .el-radio {
      margin-right: 0;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #8f8f8f;
      cursor: pointer;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.font-orange {
  font-size: 26px;
  color: #ffae00;
}
.font-red {
  color: #ff4e00;
}
.font-red1 {
  font-size: 10px;
  color: #ff4e00;
}
.font-black {
  color: #393D49;
}
.pay {
  padding: 25px 40px 40px;
  margin-bottom: 20px;
  background: #fff;
  .pay-header {
    padding-bottom: 15px;
    span {
      color: #767676;
      em {
        color: #000;
        font-size: 16px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
    .fr {
      float: right;
      font-size: 14px;
      color: #909090;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .advert{
    float: contour;
    width: 90%;
    height: auto;
    margin-bottom: 15px;
    .showImg{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .pay-container {
    .item {
      padding-top: 30px;
      border-top: 1px solid #e8e8e8;
      overflow: hidden;
      .title {
        padding-bottom: 30px;
        font-size: 14px;
        color: #767676;
      }
      .list {
        margin-bottom: 30px;
        overflow: hidden;
        li {
          margin-right: 10px;
          padding: 16px 16px 0;
          position: relative;
          float: left;
          width: 132px;
          height: 84px;
          border: 1px solid #e8e8e8;
          border-radius: 10px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 10px;
          &.big {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.bg-gray {
            display: block;
            height: 70px;
            padding: 30px 16px 0;
            background: #f3f3f3;
            color: #999;
            .label {
              padding-top: 5px;
              font-size: 12px;
              color: #c3c3c3;
            }
          }
          &.active {
            border: 1px solid #ff7a03;
            box-shadow: 1px 1px 10px #ffdcbd;
            .cur,
            .rec {
              display: block !important;
            }
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
          .rec {
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }
          .text1 {
            padding-bottom: 6px;
            color: #343851;
          }
          .text2 {
            padding-top: 6px;
            margin: 0 auto;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text3 {
            margin: 0 auto;
            width: 80%;
            color: #8e909b;
            font-size: 14px;
          }
        }
      }
      .couponlist {
        margin-bottom: 30px;
        overflow: hidden;
        li {
          margin-right: 10px;
          padding: 16px 16px 0;
          position: relative;
          float: left;
          width: 132px;
          height: 84px;
          border: 1px solid #ffdcbd;
          border-radius: 10px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 10px;
          &.big {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.bg-gray {
            border: 1px solid #9F9F9F;
            display: block;
            height: 70px;
            padding: 30px 16px 0;
            background: #f3f3f3;
            color: #999;
            .label {
              padding-top: 5px;
              font-size: 12px;
              color: #c3c3c3;
            }
          }
          &.active {
            border: 1px solid #ff7a03;
            box-shadow: 1px 1px 10px #ffdcbd;
            .cur,
            .rec {
              display: block !important;
            }
          }
          &.disabled {
            border: 1px solid #9F9F9F;
            pointer-events:none;
            opacity:0.4;
            background: #eaeaea;
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
          .rec {
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }
          .text1 {
            padding-bottom: 8px;
            color: #343851;
          }
          .text2 {
            padding-top: 8px;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text3 {
            padding-bottom: 6px;
            color: #343851;
          }
          .text4 {
            padding-top: 6px;
            margin: 0 auto;
            margin-bottom: 2px;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text5 {
            color: #ff4e00;
            font-size: 10px;
          }
        }
      }
      .type-list {
        li {
          margin-right: 30px;
          position: relative;
          float: left;
          display: flex;
          width: 178px;
          height: 80px;
          line-height: 80px;
          border: 1px solid #e8e8e8;
          border-radius: 10px;
          color: #7d7d7d;
          font-size: 16px;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          &:nth-child(2).active,
          &:nth-child(1).active {
            color: #666;
            .cur {
              display: block !important;
            }
          }
          &:nth-child(2).active {
            border: 1px solid #0c9ee0;
          }
          &:nth-child(1).active {
            //border: 1px solid #3cb73f;
            border: 1px solid #0c9ee0;
          }
          &:nth-child(2) {
            color: #0c9ee0;
            .iconfont {
              margin-right: 6px;
              color: #0c9ee0;
            }
          }
          &:nth-child(1) {
            .iconfont {
              margin-right: 15px;
              //color: #3cb73f;
              color: #0c9ee0;
            }
          }
          .iconfont {
            font-size: 38px;
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
        }
      }
    }
    .text-item {
      margin-left: 20px;
      display: inline-block;
      .text1 {
        margin-top: 72px;
        font-size: 16px;
        .font-orange {
          font-size: 30px;
        }
      }
      .text2 {
        margin-top: 10px;
        font-size: 16px;
        color: #999;
      }
      .text3 {
        margin-top: 10px;
        font-size: 16px;
        color: #999;
      }
    }
    .pay-btn-box {
      margin-top: 30px;
      .agree {
        margin-left: 40px;
        cursor: pointer;
        .el-radio {
          margin-right: 0;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #8f8f8f;
          cursor: pointer;
        }
        em {
          color: #2a2a2a;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
