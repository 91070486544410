<template>
  <el-dialog :title="payTitle" :close-on-click-modal="false" :visible.sync="visible" @close="closeDialog" class="table" width="560px">
    <div class="qrcode-way">
      <div class="mpay-qrcode-container">
        <!-- <div ref="qrcodeContainer" class="qrcode-container"></div> -->
        <!-- <img class="qrcode-container" :src="erCode" alt  /> -->
        <div ref="qrcodeContainer" class="qrcode-container"></div>
      </div>
      <div class="mpay-qrcode-tips">
        <p class="pay-info-container">
          充值金额:
          <span class="need-to-pay">￥{{nowprice}}</span>
          <span v-if="nowPreferential > 0">[赠送{{nowPreferential}}元]</span>
        </p>
        <p class="qr-tip-wx" v-if="payType == 'wechat'">
          <i style="font-size:28px; display:inline-block; vertical-align: middle; margin-left: 10px; margin-right: 10px;" class="iconfont iconweixin"></i>{{payName}}扫码付款
        </p><p class="qr-tip-zfb" v-else>
        <i style="font-size:28px; display:inline-block; vertical-align: middle;" class="iconfont iconzhifubao"></i>{{payName}}扫码付款
      </p>
        <countdown
            v-on:end_callback="CountTimeOver"
            v-if="this.info.isFlag"
            :currentTime="Number(info.currentTime)"
            :startTime="Number(info.startDate)"
            :endTime="Number(info.endDate)"
            :tipText="'订单支付开始计时'"
            :tipTextEnd="'订单支付结束计时'"
            :endText="'订单已超时，请重新生成'"
            :minutesTxt="'分'"
            :secondsTxt="'秒'"
        ></countdown>
        <div class="warning">
          <p>提示：进行支付时，请勿刷新页面或关闭支付窗口。</p>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import {
  getPayERCode,
  getPayZFBERCode,
  queryPayStatus,
  queryPayStatusNew,
  updatePayStatus,
  HPJCharge,
  queryPayStatusZFB
} from "../../../api/pay";
import { paySuccess } from "../../../api/charge";
import QRCode from "qrcodejs2";
import { message } from '@/utils/resetMessage';
import countdown from '@/components/tempcount/countdown.vue';
export default {
  components:{
    countdown
  },
  props: ["dialogVisible", "curPreferential","currentPrice","dialogPaying","currentPayType","currentCouponId"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val && !this.dialogPaying) this.getERCodeDatasZFB();
      },
      deep: true
    },
    curPreferential(newValue){
      if(!this.dialogPaying){
        this.nowPreferential = newValue;
      }
    },
    currentPrice(newValue){
      if(!this.dialogPaying){
        this.nowprice = newValue;
      }
    },
    currentPayType(newValue){
      if(!this.dialogPaying){
        this.payType = newValue;
        if(this.payType == "wechat"){
          this.payName = "微信";
          this.payTitle = "微信充值"
        }else{
          this.payName = "支付宝";
          this.payTitle = "支付宝充值";
        }
      }
    },
    currentCouponId(newValue){
      if(!this.dialogPaying){
        this.nowCouponId = newValue;
      }
    }
  },
  data() {
    return {
      visible: false,
      nowprice: this.currentPrice,
      nowPreferential: this.curPreferential,
      nowCouponId:this.currentCouponId,
      erCode:null,
      payType: "alipay",
      payName: "支付宝",
      payTitle: "支付宝充值",
      info:{
        isFlag:false,
        currentTime:'',
        startDate:'',
        endDate:''
      },
      currentOrderId:'', //当前订单ID
      isPaying:false, //是否正在支付
      timer:'' // 定时任务
    };
  },
  methods: {
    //二维码
    showQRCode(url) {
      //先清空
      this.$refs.qrcodeContainer.innerHTML = "";
      //再生成二维码
      new QRCode(this.$refs.qrcodeContainer, {
        text: url,
        width: 160,
        height: 160,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
      });
    },
    getRandomCode(length){
      if (length > 0) {
        var data = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    getERCodeDatas() {
      //获取二维码链接
      let dataForm = {
        order_price: this.nowprice,
        order_name: this.getRandomCode(10),
        version: "1.1",
        return_url:"",
        otherPrice:this.nowPreferential,
        userCouponId:"",
        callback_url:""
      };
      this.erCode = null;
      HPJCharge(dataForm).then((result) => {
        this.erCode = result.urlQrCode;
        this.$emit("onPay", true); // 创建订单的状态
        // this.$nextTick(() => {
        //   this.showQRCode(result.qrUrl);
        // });
        this.info.isFlag = true;
        this.info.startDate = this.info.currentTime = new Date().getTime();
        this.info.endDate = this.info.startDate + 60*3*1000;
        this.currentOrderId = result.orderId;
        //查询状态
        this.getPayStatus();
      }).catch((error) => {
        message.error({
            message: error.message,
            duration: 4000,
            offset: 80
          })
      })
    },
    //支付宝面对面充值
    getERCodeDatasZFB() {
      //获取二维码链接
      let dataForm = {
        order_type: this.payType,
        order_price: this.nowprice,
        other_price: this.nowPreferential,
        order_name: this.getRandomCode(10),
        extension: "pptuku"
      };
      getPayZFBERCode(dataForm).then((result) => {
        this.$emit("onPay", true); // 创建订单的状态
        this.$nextTick(() => {
          this.showQRCode(result.qrUrl);
        });
        this.info.isFlag = true;
        this.info.startDate = this.info.currentTime = new Date().getTime();
        this.info.endDate = this.info.startDate + 60*3*1000;
        this.currentOrderId = result.orderId;
        //查询状态
        this.getPayStatusZFB();
      }).catch((error) => {
        message.error({
            message: error.message,
            duration: 4000,
            offset: 80
          })
      })
    },
    getPayStatus(){ //查询状态
      if(this.currentOrderId != null && this.currentOrderId != ''){
        this.timer = setTimeout(() =>{
          let data1 = {
            orderId:this.currentOrderId
          }
          console.log(JSON.stringify(data1));
          queryPayStatusNew(data1).then((result) => {
            if(result.payStatus == 1){ //支付成功
              //支付成功后需要加状态
              if(!this.isPaying){
                this.isPaying = true;
                this.doPaySuccess();
              }
            }else if(result.payStatus == 3){ //过期
              this.doUpdatePayStatus();
              this.$emit("onPay", false); // 创建订单的状态
            }else{ //继续查询
              this.getPayStatus();
            }
          }).catch((error) => {
            this.getPayStatus();
          })
        },3000);
      }
    },
    getPayStatusZFB(){ //支付宝查询状态
      if(this.currentOrderId!=null && this.currentOrderId != ''){
        this.timer = setTimeout(() =>{
          let data1 = {
            orderId:this.currentOrderId
          }
          queryPayStatusZFB(data1).then((result) => {
            console.log(JSON.stringify(result));
            if(result.payStatus == 1){ //支付成功
              //支付成功后需要加状态
              if(!this.isPaying){
                this.isPaying = true;
                this.doPaySuccess(); //和支付宝公用
              }
            }else if(result.payStatus == 3){ //过期
              this.doUpdatePayStatus();
              this.$emit("onPay", false); // 创建订单的状态
            }else{ //继续查询
              this.getPayStatusZFB();
            }
          }).catch((error) => {
            this.getPayStatusZFB();
          })
        },3000);
      }
    },
    doUpdatePayStatus() { //更新订单状态
      if (this.currentOrderId != null && this.currentOrderId != '') {
        let data2 = {
          orderId : this.currentOrderId,
          status:3
        }
        updatePayStatus(data2).then((result) => {
          console.log(JSON.stringify(result));
          this.$emit("closeDialog");
        }).catch((error) => {
          console.log(JSON.stringify(error));
        })
      }
    },
    doUpdatePayStatus_close() { //更新订单状态
      if (this.currentOrderId != null && this.currentOrderId != '') {
        // let data2 = {
        //   orderId : this.currentOrderId,
        //   status:3
        // }
        // updatePayStatus(data2).then((result) => {
        //   this.$emit("closeDialog");
        // }).catch((error) => {
        //   console.log(JSON.stringify(error));
        // })
        clearTimeout(this.timer);
        this.$emit("closeDialog");
      }
    },
    doPaySuccess(){
      if (this.currentOrderId != null && this.currentOrderId != '') {
        // let data3 = {
        //   orderId : this.currentOrderId,
        //   price: this.nowprice,
        //   payType:this.payType,
        //   otherPrice: this.nowPreferential,
        //   userCouponId: this.nowCouponId,
        // }
        // paySuccess(data3).then((result) => {
        //   this.$emit("onPay", false); // 创建订单的状态
        //   this.isPaying = false; //不在正在支付
        //   this.$emit("closeDialog");
        //   message.success({
        //     message: '您已充值成功，谢谢',
        //     duration: 4000,
        //     offset: 80
        //   })
        // }).catch((error) => {
        //   //再次尝试一次
        //   paySuccess(data3).then((result) => {
        //     this.$emit("onPay", false); // 创建订单的状态
        //     this.isPaying = false; //不在正在支付
        //     this.$emit("closeDialog");
        //     message.success({
        //       message: '您已充值成功，谢谢',
        //       duration: 4000,
        //       offset: 80
        //     })
        //   }).catch((error) => {
        //     this.$emit("onPay", false); // 创建订单的状态
        //     this.isPaying = false; //不在正在支付
        //   })
        // })
        this.$emit("onPay", false); // 创建订单的状态
        this.isPaying = false; //不在正在支付
        this.$emit("closeDialog");
        clearTimeout(this.timer);
        message.success({
          message: '您已充值成功，谢谢',
          duration: 4000,
          offset: 80
        })
      }
    },
    closeDialog() {
      this.$emit("onPay", false); // 创建订单的状态
      this.doUpdatePayStatus_close();
    },
    CountTimeOver(){ //倒计时结束触发
      this.$emit("onPay", false); // 创建订单的状态
      this.doUpdatePayStatus_close();
    }
  }
};
</script>

<style lang="scss" scoped>
.font-orange {
  font-size: 26px;
  color: #ffae00;
}
.qrcode-way {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .mpay-qrcode-container {
    position: relative;
    width: 160px;
    height: 160px;
    padding: 5px;
    text-align: center;
    background: url(../../../assets/images/pay/codeBox.png) no-repeat;
    background-size: contain;
  }
  .qrcode-container {
    width: 100%;
    height: 92%;
    position: relative;
  }
  .mpay-qrcode-tips {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .pay-info-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    .need-to-pay {
      font-size: 24px;
      color: #fa2323;
      font-weight: 700;
      margin: 0 8px 0 2px;
    }
  }
  .qr-tip-zfb {
    font-size: 16px;
    color: #0c9ee0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .qr-tip-wx {
    font-size: 16px;
    color: #3cb73f;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .warning{
    display: block;
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
    p{
      font-size: 14px;
      color: #5e6d82;
      line-height: 1.5em;
    }
  }
}
</style>
